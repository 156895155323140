<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        fluid
        :src="pageData.favicon || pageData.logo"
        alt="Fems"
        :style="{ height: '30px' }"
      />
      <h2
        class="brand-text text-primary ml-1"
        :style="{ lineHeight: '30px' }"
      >
        {{ pageData.slug.toUpperCase() }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Refresh token Setup
        </h2>
        <p class="mb-2">
          Please Wait
        </p>
        <preloader v-if="isLoading" />

      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

import LocalStorageService from '@/@service/utils/LocalStorage.service'
import AuthService from '@/@service/api/Auth.service'
import VueJwtDecode from 'vue-jwt-decode'
import Preloader from '../components/preloader/preloader.vue'

export default {
  components: {
    // VuexyLogo,
    BLink,
    BImg,
    Preloader,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      accessToken: LocalStorageService.get('accessToken'),
      refreshToken: LocalStorageService.get('refreshToken'),
      pageData: {},
      isLoading: true,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    if (this.getPageData && this.accessToken) {
      this.onCoreRefreshTokenSetup()
      this.pageData = this.getPageData
      return
    }
    this.onLogout()
  },
  methods: {
    onCoreRefreshTokenSetup() {
      const decodeToken = VueJwtDecode.decode(this.accessToken)
      if (this.accessToken && new Date(decodeToken.exp * 1000) < new Date().getTime()) {
        this.onRequestLogin()
        return
      }
      this.onLogout()

      // this.onLogout()
    },

    onRequestLogin() {
      LocalStorageService.delete('accessToken')
      if (!this.refreshToken) {
        this.onLogout()
      }
      LocalStorageService.set('accessToken', this.refreshToken)
      const isRememberMe = LocalStorageService.get('isRememberMe')
      AuthService.refreshLogin({ remember_me: isRememberMe }).then(
        response => {
          const res = response.data
          LocalStorageService.delete('accessToken')
          if (res.status) {
            // Update accessToken in localStorage
            LocalStorageService.set('accessToken', res.access_token)
            this.onRefreshToken()
          } else {
            // eslint-disable-next-line no-use-before-define
            this.onLogout()
          }
        },
      ).catch(() => {
        this.onLogout()
      })
    },

    onRefreshToken() {
      AuthService.refreshTokenGenerate().then(
        response => {
          const res = response.data
          if (res.status) {
            LocalStorageService.set('refreshToken', res.refresh_token)
            this.isLoading = false
            window.location.href = `${process.env.VUE_APP_CORE_BASE_URL}login/fems_admin_reset_token/?t=${LocalStorageService.get('accessToken')}`
            // window.location.reload()
          }
        },
      ).catch(() => {
        this.onLogout()
      })
    },

    // eslint-disable-next-line class-methods-use-this
    onLogout() {
      LocalStorageService.clear()
      window.location.href = `${process.env.VUE_APP_CORE_BASE_URL}login/logout_alt`
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
